<template>
  <div
    class="sidebar-pwa-widget"
    :class="modifierClasses"
    data-t="sidebar-pwa-widget"
    @click="installPwa"
  >
    <img
      class="betone-image"
      width="32"
      height="32"
      src="./logo.svg"
      alt="icon"
    />
    <div v-if="isCompact" class="icon-wrapper">
      <StIcon class="download-icon" name="download" size="14" />
    </div>
    <div class="title">{{ t('menu.betoneApp') }}</div>
    <div class="platforms-icons">
      <StIcon name="c-apple" size="20" />
      <StIcon name="c-android" size="20" />
      <StIcon name="c-windows" size="20" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePwaInstall } from './usePwaInstall'

const { t } = useI18n()
const isCompact = inject<Ref<boolean>>('isCompact')

const props = defineProps<{
  mobile?: boolean
}>()
const { installPwa } = usePwaInstall()
const modifierClasses = computed(() => [
  {
    mobile: props.mobile,
    compact: isCompact?.value,
  },
])
</script>

<style scoped>
.title {
  font: var(--desktop-text-sm-medium);
  color: var(--text-primary);
}

.betone-image {
  width: 32px;
}

.platforms-icons {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;
  margin-left: auto;
}

.sidebar-pwa-widget {
  cursor: pointer;

  position: relative;

  display: flex;
  gap: var(--spacing-100);
  align-items: center;
  align-self: stretch;

  padding: var(--spacing-100);

  background: var(--background-primary);
  border-radius: var(--border-radius-100);

  transition: opacity 0.2s;

  &.mobile {
    margin: var(--spacing-200) var(--spacing-200) var(--spacing-100)
      var(--spacing-200);
    background: var(--button-gray-default);
  }

  &.compact {
    padding: var(--spacing-075);
    font-size: 0;

    .betone-image {
      width: 36px;
      height: 36px;
    }

    .title,
    .platforms-icons {
      opacity: 0;
    }
  }
}

.icon-wrapper {
  position: absolute;
  right: 2px;
  bottom: 2px;

  padding: var(--spacing-025);

  color: var(--icon-primary);

  background: var(--background-primary);
  backdrop-filter: blur(10px);
  border-radius: var(--border-radius-full);
}
</style>
